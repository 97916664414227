html,
body {
  margin: 0;
  padding: 0;
}

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.33);
  }
  100% {
    transform: scale(1);
  }
}

button:not(:disabled) {
  cursor: pointer;
}

.pulse {
  animation-name: pulse_animation;
  animation-duration: 2400ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
