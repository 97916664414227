html,
body {
  margin: 0;
  padding: 0;
}

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.33);
            transform: scale(1.33);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.33);
            transform: scale(1.33);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

button:not(:disabled) {
  cursor: pointer;
}

.pulse {
  -webkit-animation-name: pulse_animation;
          animation-name: pulse_animation;
  -webkit-animation-duration: 2400ms;
          animation-duration: 2400ms;
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

